export const artefactPosition = {
	'bottomRight': {
		bottom: 'auto',
		right: '10%',
		top: '100%',
		left: 'auto',
		transform: 'translateY(-50%)',
		mobileRight: '-20%',
		mobileLeft: 'auto'
	},
	'topRight': {
		bottom: '100%',
		right: '10%',
		top: 'auto',
		left: 'auto',
		transform: 'translateY(50%)',
		mobileRight: '-10%',
		mobileLeft: 'auto'
	},
	'bottomLeft': {
		bottom: 'auto',
		right: 'auto',
		top: '100%',
		left: '10%',
		transform: 'translateY(-50%)',
		mobileRight: 'auto',
		mobileLeft: '-20%'
	},
	'topLeft': {
		bottom: '100%',
		right: 'auto',
		top: 'auto',
		left: '10%',
		transform: 'translateY(50%)',
		mobileRight: 'auto',
		mobileLeft: '-10%'
	},
}

export const nudgeX = {
	'right': 'var(--xl)',
	'left': 'calc(var(--xl) * -1)',
}

export const nudgeY = {
	'up': 'calc(var(--xxl) * -1)',
	'down': 'var(--xxl)',
}

export const padding = {
	'small': 'var(--xl)',
	'medium': 'var(--xxl)',
	'large': 'var(--xxxxl)'
}

export const mobileArtefactPosition = {
	0: 'topLeft',
	1: 'topRight',
	2: 'topLeft',
	3: 'topRight',
}